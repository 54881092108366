<!-- 车辆入场 -->
<template>
    <div class='content'>
        <div class="carsOut" v-if="isCarInOrOut">
            <div class="leftDetailBox">
                <div class="topParkNameTime">
                    <div class="topCarOwner">
                        <div class="leftSpan">
                            <p class="carBrandSpan">{{ carsDetail.plate_number }}</p>
                            <p class="parkPlaceSpan">{{ carsDetail.parking_name }}</p>
                        </div>
                        <div class="rightSpan">
                            <p>{{ getWayName('CarOwnerCardType', carsDetail.car_owner_card_type) }}</p>
                        </div>
                    </div>
                    <div class="footInOutTime">
                        <div class="leftInTime">
                            <p class="timeSpan">
                                {{ $moment(carsDetail.go_in_time).format('HH:mm:ss') }}</p>
                            <p class="dateSpan"> {{ $moment(carsDetail.go_in_time).format('YYYY-MM-DD') }}</p>
                            <p class="parkSpan">{{ carsDetail.entryname }}</p>
                        </div>
                        <div class="innerDuring">{{getDiffTime(carsDetail.go_out_time.split('T').join(' '),carsDetail.go_in_time.split('T').join(' '))}}</div>
                        <!-- <div class="innerDuring">{{ getDiffTime(carsDetail.go_out_time, carsDetail.go_in_time) }}</div> -->
                        <div class="rightOutTime">
                            <p class="timeSpan">
                                {{ $moment(carsDetail.go_out_time).format('HH:mm:ss') }}</p>
                            <p class="dateSpan"> {{ $moment(carsDetail.go_out_time).format('YYYY-MM-DD') }}</p>
                            <p class="parkSpan">{{ carsDetail.exportname }}</p>
                        </div>
                    </div>
                </div>
                <div class="footParkMoney">
                    <div class="amountReceivable">
                        <div class="topMoney">
                            <span>¥</span><span class="realMoney">{{ carsDetail.order_Info.calculate_cost }}</span>
                        </div>
                        <div class="borderBox"></div>
                        <div class="boxName">应收金额</div>
                    </div>
                    <div class="preferential">
                        <div class="topMoney">
                            <span>¥</span><span class="realMoney">{{ carsDetail.order_Info.discount_amount }}</span>
                        </div>
                        <div class="borderBox"></div>
                        <div class="boxName">优惠金额</div>
                    </div>
                    <div class="amountFunds">
                        <div class="topMoney">
                            <span>¥</span><span class="realMoney">{{ carsDetail.order_Info.final_cost }}</span>
                        </div>
                        <div class="borderBox"></div>
                        <div class="boxName">实收金额</div>
                    </div>
                </div>
            </div>
            <div class="rightImgBox">
                <div class="inParkPicBox">
                    <div class="positionBox">
                        <!-- <img :src=carsDetail.go_in_image alt=""> -->
                        <div v-if="carsDetail.go_in_image">
                            <el-image class="elImage" style="width: 100%; height: 100%" :src="carsDetail.go_in_image"
                            :preview-src-list="formatImage(carsDetail.go_in_image)" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="iconfont lebo-picerr"></i>
                                <span>加载失败</span>
                            </div>
                        </el-image>
                        </div>
                        <div v-else class="noPic">
                            <span>暂无入场图片</span>
                        </div>
                        <div class="maskBox">入场图片</div>
                    </div>
                </div>
                <div class="outParkPicBox">
                    <div class="positionBox">
                        <!-- <img :src=carsDetail.go_out_image alt=""> -->
                        <div v-if="carsDetail.go_out_image">
                            <el-image class="elImage" style="width: 100%; height: 100%" :src="carsDetail.go_out_image"
                            :preview-src-list="formatImage(carsDetail.go_out_image)" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="iconfont lebo-picerr"></i>
                                <span>加载失败</span>
                            </div>
                        </el-image>
                        </div>
                        <div v-else class="noPic">
                            <span>暂无出场图片</span>
                        </div>
                        <div class="maskBox">出场图片</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carsIn" v-else>
            <div class="leftDetailBox" style="text-align: left;">
                <ul>
                    <li class="inCarLi">
                        <span class="leftSpanGroup">车牌:</span><span class="rightSpanGroup">{{ carsDetail.plate_number
                        }}</span>
                    </li>
                    <li class="inCarLi">
                        <span class="leftSpanGroup">类型:</span><span class="rightSpanGroup">{{ getWayName('CarOwnerCardType',
                            carsDetail.car_owner_card_type) }}</span>
                    </li>
                    <li class="inCarLi">
                        <span class="leftSpanGroup">停车场:</span><span class="rightSpanGroup">{{ carsDetail.parking_name
                        }}</span>
                    </li>
                    <li class="inCarLi">
                        <span class="leftSpanGroup">入口:</span><span class="rightSpanGroup">{{ carsDetail.entryname }}</span>
                    </li>
                    <li class="inCarLi">
                        <span class="leftSpanGroup">时间:</span>
                        <span class="rightSpanGroup">{{ $moment(carsDetail.go_in_time).format('HH:mm:ss') }}</span>
                    </li>
                    <li class="inCarLi">
                        <span class="leftSpanGroup">日期:</span><span class="rightSpanGroup">{{
                            $moment(carsDetail.go_in_time).format('YYYY-MM-DD') }}</span>
                    </li>
                </ul>

            </div>
            <div class="rightImgBox">
                <div class="inParkPicBox">
                    <div class="positionBox">
                        <!-- <img :src=carsDetail.go_in_image alt=""> -->
                        <div v-if="carsDetail.go_in_image">
                            <el-image class="elImage" style="width: 100%; height: 100%" :src="carsDetail.go_in_image"
                            :preview-src-list="formatImage(carsDetail.go_in_image)" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="iconfont lebo-picerr"></i>
                                <span>加载失败</span>
                            </div>
                        </el-image>
                        </div>
                        <div v-else class="noPic">
                            <span>暂无入场图片</span>
                        </div>

                        <div class="maskBox">入场图片</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: '',
  components: {},
  props: {
    // carsDetail: { require: true }
    carsDetail: { require: true }
  },
  data () {
    return {
      isCarInOrOut: true
    }
  },
  watch: {

    carsDetail: {
      immediate: true,
      deep: true,
      handler (newurl, oldurl) {
        if (newurl.out_in_type === 2) {
          this.isCarInOrOut = true
        } else {
          this.isCarInOrOut = false
        }
        console.log('传入信息', newurl)
      }
    }

  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])

  },
  methods: {
    // 计算停车间隔时长

    getDiffTime (newDate, oldDate) {
      var subtime = (new Date(newDate) - new Date(oldDate)) / 1000 // 计算时间差,并将毫秒转化为秒
      var days = parseInt(subtime / 86400) // 天  24*60*60*1000
      var hours = parseInt(subtime / 3600) - 24 * days // 小时  60*60  总小时数-过去小时数=现在小时数
      var mins = parseInt(subtime % 3600 / 60) // 分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
      var secs = parseInt(subtime % 60) // 以60秒为一整份  取余  剩下秒数
      //   const start = this.$moment(newDate).format('YYYY-MM-DD HH:mm:ss')
      //   const end = this.$moment(oldDate).format('YYYY-MM-DD HH:mm:ss')
      //   const days = this.$moment(start).diff(end, 'days')
      //   const hours = this.$moment(start).diff(end, 'hours')
      //   const mins = this.$moment(start).diff(end, 'minutes')
      return days + ' 天 ' + hours + ' 小时 ' + mins + ' 分钟'
    }
  },

  created () { },
  mounted () {
  }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .carsOut {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
    }

    .carsIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: start;
        padding: 0 20px;

        .inCarLi {
            margin-bottom: 15px;
        }

        .leftSpanGroup {
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            text-align: left;
            display: inline-block;
            width: 100px;
        }

        .rightSpanGroup {
            text-align: left;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
        }
    }

    .leftDetailBox {
        width: 60%;
        height: 100%;
        padding: 15px;
        box-sizing: border-box;

        .topParkNameTime {
            border: 1px solid #E6E6E6;
            width: 550px;
            height: 230px;
            padding: 5px 15px;
            box-sizing: border-box;

            .topCarOwner {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #F2F2F2;

                .leftSpan {
                    text-align: left;

                    .carBrandSpan {
                        font-size: 24px;
                        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                        font-weight: 500;
                        color: #666666;
                        line-height: 0px;
                    }

                    .parkPlaceSpan {
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                    }
                }

                .rightSpan>p {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #CCCCCC;
                    line-height: 2px;
                }
            }

            .footInOutTime {
                display: flex;
                justify-content: space-between;

                .leftInTime {
                    text-align: left;
                }

                .innerDuring {
                    width: 250px;
                    height: 30px;
                    background-image: url('~@/assets/img/ychj/timeduring.png');
                    background-size: 99% 80%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-top: 40px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #01A6FB;
                }

                .rightOutTime {
                    text-align: right;
                }

                .timeSpan {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                }

                .dateSpan {
                    margin-top: 30px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                }

                .parkSpan {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                }
            }

        }

        .footParkMoney {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .realMoney {
                font-size: 26px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
            }

            .boxName {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                margin-top: 30px;
            }

            .amountReceivable {
                width: 150px;
                height: 180px;
                background-image: url('~@/assets/img/ychj/bgiamountReceivable.png');
                background-position: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                text-align: left;
                padding-left: 20px;
                box-sizing: border-box;

                .topMoney {
                    color: #01A6FB;
                    width: 100%;
                    margin-top: 40px;

                    span {
                        margin-right: 5px;
                    }
                }

                .borderBox {
                    width: 50px;
                    height: 20px;
                    border-bottom: 2px solid #01A6FB;
                }
            }

            .preferential {
                width: 150px;
                height: 180px;
                background-image: url('~@/assets/img/ychj/bgipreferential.png');
                background-position: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                text-align: left;
                padding-left: 20px;
                box-sizing: border-box;

                .topMoney {
                    color: #1ACD90;
                    width: 100%;
                    margin-top: 40px;

                    span {
                        margin-right: 5px;
                    }
                }

                .borderBox {
                    width: 50px;
                    height: 20px;
                    border-bottom: 2px solid #1ACD90;
                }
            }

            .amountFunds {
                width: 150px;
                height: 180px;
                background-image: url('~@/assets/img/ychj/bgiamountFunds.png');
                background-position: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                text-align: left;
                padding-left: 20px;
                box-sizing: border-box;

                .topMoney {
                    color: #F55265;
                    width: 100%;
                    margin-top: 40px;

                    span {
                        margin-right: 5px;
                    }
                }

                .borderBox {
                    width: 50px;
                    height: 20px;
                    border-bottom: 2px solid #F55265;
                }
            }
        }
    }

    .rightImgBox {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 5px;

        .positionBox {
            width: 100%;
            height: 100%;
            position: relative;

            // img {
            //     width: 100%;
            //     height: 200px;
            //     border-radius: 8px;
            //     border: 1px solid black;
            // }
            .elImage {
                width: 100%;
                height: 200px !important;
                border-radius: 8px;
                border: 1px solid black;
                box-sizing: border-box;

                /deep/ .image-slot {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    background-color: #f5f7fa;
                    .iconfont {
                        font-size: 20px;
                    }
                }

            }
            .noPic{
                width: 100%;
                height: 200px !important;
                background-color: #f5f7fa;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999;
            }
            .maskBox {
                position: absolute;
                bottom: 6px;
                width: 100.5%;
                height: 35px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 35px;
                background-color: rgba(0, 0, 0, .5);
                border-radius: 0 0 8px 8px;
            }
        }

        .inParkPicBox {
            width: 100%;
            height: 45%;
            padding: 10px;
            box-sizing: border-box;
        }

        .outParkPicBox {
            width: 100%;
            height: 45%;
            padding: 10px;
            box-sizing: border-box;
        }
    }

}
</style>
