<!-- 车辆进出监控 -->
<template>
  <div class="neiyi">
     <!-- 加载中 -->
     <div v-if="loading" class="loadingBox">
      <div class="loadingSpan">设备加载中...</div>
    </div>
    <template v-else>
      <video class="videoBox" v-if="sources !== ''" ref="videoPlayers" width="100%" :height="fullscreenstatus ? '100%' : height" :id="id" autoplay
      muted style="object-fit: fill" @dblclick="dblclick" @playing="playing"></video>
    <div class="replacediv" v-if="sources === ''">
      <!-- <span> 无监控源 </span> -->
    </div>
    </template>

    <!-- 底部操纵栏 -->
    <div class="needMaskzhehzao">
      <div class="zhezhao">
        <div class="left">{{ titleName }}</div>
        <div class="right">
          <div class="tackCall">
            <span v-show="callingSatuts === 1">有人求助...</span>
            <span v-show="callingSatuts === 2 && myCallUp">正在通话中...</span>
            <span v-show="callingSatuts === 2 && !myCallUp">{{ receiveName }}正在语音通话...</span>
            <!-- <div class="callOut" v-show="callingSatuts === 0">
              <div class="iconOut"></div>
              语音通话
            </div> -->
            <div class="callIn" @click="tackCallIn" v-if="callingSatuts === 1 && canTakePhone" >
              <div class="iconIn"></div>
              立即接听
            </div>
            <div class="calling" @click="tackoffCall" v-if="callingSatuts === 2 && myCallUp">
              <div class="iconcalling"></div>
              结束通话
            </div>
          </div>
          <div class="openDoor">
              <el-button type="text" :disabled="canClickDoor" @click="openDoor" style="width:100%;"></el-button>
            </div>
          <div class="closeDoor" @click="closeDoor"> <el-button type="text" :disabled="canClickDoor" style="width:100%;"></el-button></div>
        </div>
      </div>
    </div>
    <!-- 来电遮罩 -->
    <div class="callInMask" v-show="callingSatuts === 1">
      <div class="tackCallingBgi"></div>
    </div>
    <div id="callingUp"></div>
  </div>
</template>

<script>
import flv from 'flv.js'
import TRTC from 'trtc-js-sdk'
import { getVideoAddress, modifyAudioRequestStatus, issueClosingCommand, issueOpeningCommand } from '@/api/ychjapi'
export default {
  //   muted
  // autoplay
  props: ['id', 'height', 'fullscreenstatus', 'mainid', 'titleName', 'videoData', 'canTakePhone', 'uniqueIdentifier', 'tencentAppId'],
  data () {
    return {
      loading: true,
      //   url: "http://vjs.zencdn.net/v/oceans.mp4",
      // url: 'wss://aicity.lebopark.com/188_128_0_250/rtp/541915059090100224.flv',
      url: '',
      player: null,
      isOver: false,
      timer: null,
      sources: '',
      MediaServerId: '',
      callingSatuts: 0, // 请求状态枚举 0:未请求 1：请求通话中 2：服务端已接听 3:结束通话
      snType: '', // 请求获取视频信息的sn 字段值
      receiveName: '', // 接听者名字
      myCallUp: false, // 是否为本机接听
      doorData: {}, // 开关闸道口数据
      canClickDoor: false, // 是否可以点击开关闸
      firstClickTime: 0, // 第一次点击时间
      localStream: '',
      client: ''

    }
  },
  watch: {
    mainid: {
      deep: true,
      handler (newurl, oldurl) {
        var videoElement = document.getElementById(this.id)
        if (videoElement) {
          if (!this.loading) {
            this.loading = true
          }
          if (this.player) {
            this.player.unload()
            this.player.detachMediaElement()
            this.player.destroy()
            this.player = null
          }
          this.initVedioUrl()
        }
      }
    },
    videoData: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        // console.log('newval', newVal)
        this.callingSatuts = newVal.audiovideoType // 更新接听状态
        this.receiveName = newVal.receive_name // 更新接听者名字
        if (newVal.unique_identifier !== this.uniqueIdentifier) {
          // 不是本机接听
          this.myCallUp = false
        } else {
          // 是本机接听
          this.myCallUp = true
        }
      }
    },
    microphoneId (val) {
      this.switchDevice('audio', val)
    }
  },
  computed: {
    realeid: function () {
      return this.id
    }
  },
  methods: {
    // 获取本窗口的监控视频
    async fngetVideoAddress () {
      const obj = {}
      obj.sn = this.videoData.camerasn || this.videoData.sn
      // obj.sn = 'b25f2f6a-e7676485'
      obj.type = 'flv'
      let res = ''
      if (obj.sn) {
        res = await getVideoAddress(obj)
        if (!res.Data.Bodystr.url) {
          this.sources = ''
          this.loading = false
        } else {
          this.sources = res.Data.Bodystr.url
          setTimeout(() => {
            this.newplayer(res.Data.Bodystr.url)
          }, 300)
        }
        // console.log('单页面获取到的视频数据', res)
      }
      // console.log('获取视频流', res)
    },
    newplayer (url) {
      console.log('url', url)
      if (flv.isSupported()) {
        var videoElement = document.getElementById(this.id)
        this.player = flv.createPlayer(
          {
            type: 'flv',
            // url: "http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=10",
            url: url,
            isLive: true,
            cors: true
          },
          {
            enableStashBuffer: false,
            stashInitialSize: 128,
            autoCleanupSourceBuffer: true
          }
        )
        this.player.attachMediaElement(videoElement)
        this.player.load() // 加载
      }
    },
    flv_start () {
      if (!this.player) return
      this.player
        .play()
        .then(() => {
          // 音频加载成功
          // 音频的播放需要耗时
          setTimeout(() => {
            // 后续操作
          }, 1000) // audio.duration 为音频的时长单位为秒
        })
        .catch((e) => {
          // 音频加载失败
        })
    },
    flv_pause () {
      if (!this.player) return
      this.player.pause()
    },
    flv_destroy () {
      if (!this.player) return
      this.resetPlayer()
    },
    resetPlayer () {
      if (!this.player || !this.sources) return
      this.player.pause()
      this.player.unload()
      this.player.detachMediaElement()
      this.player.destroy()
      this.player = null
    },
    dblclick () {
      this.requestFullScreen(document.getElementById(this.id))
    },
    fullScreen () {
      // 全屏展示
      this.requestFullScreen(document.getElementById(this.id))
    },
    // 全屏
    requestFullScreen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    // 退出全屏
    exitFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        docHtml.style.cssText = ''
        docBody.style.cssText = ''
        videobox.style.cssText = ''
        document.IsFullScreen = false
      }
    },
    playing () {
      if (this.loading) {
        this.loading = false
      }
    },
    // 接听电话
    tackCallIn () {
      this.myCallUp = true
      const obj = {}
      obj.id = this.videoData.id
      obj.name = this.videoData.parkname
      obj.type = 2
      obj.parprakingID = this.videoData.parprakingID
      obj.unique_identifier = this.uniqueIdentifier
      this.fnmodifyAudioRequestStatus(obj)
      this.callingSatuts = 2
      this.createVideoRoom() // 创建音频房间
    },
    // 修改通话状态
    async fnmodifyAudioRequestStatus (obj) {
      const res = await modifyAudioRequestStatus(obj)
      // console.log('是否接听/挂断成功？', res)
    },
    // 结束通话
    tackoffCall () {
      const obj = {}
      obj.id = this.videoData.id
      obj.name = this.videoData.parkname
      obj.type = 3
      obj.parprakingID = this.videoData.parprakingID
      this.fnmodifyAudioRequestStatus(obj)
      this.myCallUp = false
      this.callingSatuts = 0
      // this.leaveRoom() // 退出房间
    },
    // 开闸
    async openDoor () {
      const firstTime = Date.now()
      if (firstTime.valueOf() - this.firstClickTime > 1000) {
        this.canClickDoor = true
        await issueOpeningCommand(this.doorData)
        this.firstClickTime = firstTime
        setTimeout(() => {
          this.canClickDoor = false
        }, 1000)
      }
    },
    // 关闸
    async closeDoor () {
      const firstTime = Date.now()
      if (firstTime.valueOf() - this.firstClickTime > 1000) {
        this.canClickDoor = true
        await issueClosingCommand(this.doorData)
        this.firstClickTime = firstTime
        setTimeout(() => {
          this.canClickDoor = false
        }, 1000)
      }
    },
    // // 音频流创建
    async createVideoRoom () {
      // console.log('通话', this.tencentAppId.sdkAppId, this.videoData.id, this.uniqueIdentifier, this.tencentAppId.userSig)
      const sdkAppId = this.tencentAppId.sdkAppId
      const roomId = this.videoData.id
      const userId = this.uniqueIdentifier
      // const userId = '10086'
      const userSig = this.tencentAppId.userSig
      const useStringRoomId = true
      this.client = TRTC.createClient({ mode: 'rtc', sdkAppId, userId, userSig, useStringRoomId })
      // 1.监听事件
      this.client.on('stream-added', event => {
        console.log('远端流增加event', event)
        const remoteStream = event.stream
        console.log('远端流增加: ' + remoteStream.getId())
        // 订阅远端流
        this.client.subscribe(remoteStream)
      })
      this.client.on('stream-subscribed', event => {
        // 远端流订阅成功
        this.remoteStream = event.stream
        const remotePlayerElement = document.createElement('div')
        remotePlayerElement.id = 'remote-stream-' + this.remoteStream.getId()
        document.body.appendChild(remotePlayerElement)
        // 播放远端流，传入的元素 ID 必须是页面里存在的 div 元素
        this.remoteStream.play('callingUp')
        console.log('远端流订阅成功', event)
      })
      // 2.进房成功后开始推流
      try {
        await this.client.join({ roomId })
        this.localStream = TRTC.createStream({ userId, audio: true, video: false })
        await this.localStream.initialize()
        // 播放本地流
        this.localStream.play('callingUp')
        await this.client.publish(this.localStream)
        console.log('进入成功')
      } catch (error) {
        console.error('本地流错误', error)
      }
    },
    // 获取麦克风权限
    getDeviceList () {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(() => {
          getDeviceList()
        })
      navigator.mediaDevices.addEventListener('devicechange', getDeviceList)
      var that = this
      async function getDeviceList () {
        var deviceList = await TRTC.getMicrophones()
        that.microphoneId = deviceList[0].deviceId
        that.switchDevice('audio', that.microphoneId)
      }
    },
    // 退出音频流
    async leaveRoom () {
      this.localStream.close()
      await this.client.leave()
      // 退房成功，如果没有调用 client.destroy()，可再次调用 client.join 重新进房开启新的通话
      // 调用 destroy() 结束当前 client 的生命周期
      this.client.destroy()
    },
    switchDevice (type, deviceId) {
      try {
        if (this.localStream) {
          this.localStream.switchDevice(type, deviceId)
          console.log(`Switch ${type} device success.`)
        }
      } catch (error) {
        console.error('switchDevice failed', error)
        console.log(`Switch ${type} device failed.`)
      }
    }

  },
  beforeDestroy () {
    // if (this.myCallUp === true && this.callingSatuts === 2) {
    //   // alert('离开页面将断开通话')
    //   this.tackoffCall()
    // }
  },
  created () {
    if (flv.isSupported()) {
      if (typeof this.player !== 'undefined') {
        if (this.player) {
          this.player.unload()
          this.player.detachMediaElement()
          this.player = null
        }
      }
      this.player = flv.createPlayer(
        {
          type: 'flv',
          url: this.sources,
          // url: 'wss://fatcity.lebopark.com/188_128_0_240/rtp/559399475282448384.flv',
          isLive: true,
          cors: true
        },
        {
          enableStashBuffer: false,
          stashInitialSize: 128,
          autoCleanupSourceBuffer: true
        }
      )
    }
    this.getDeviceList()
  },
  mounted () {
    this.fngetVideoAddress() // 获取单区块视频
    // 设置开关闸道口数据
    this.doorData = {
      id: this.videoData.id,
      name: this.videoData.parkname,
      // type: this.videoData.audiovideoType,
      parprakingID: this.videoData.parprakingID,
      sn: this.videoData.sn || this.videoData.camerasn
    }
    console.log('获取传来的信息对应！', this.id, this.titleName, this.videoData)
  },
  destroyed () {
    // console.log("==-===-=destroyed=======realeid====" + this.realeid);
    this.resetPlayer()
  }
}
</script>

<style scoped lang="less">
.neiyi {
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 99;
}
.loadingBox {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
    background-image: url('~@/assets/img/noMessage/loading.gif');
    background-size: 15% 20%;
    background-position: center center;
    background-repeat: no-repeat;

    .loadingSpan {
      position: absolute;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      bottom: 20%;
    }
  }
.needMaskzhehzao {
  z-index: 9999;
  .zhezhao {
    position: absolute;
    display: flex;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 50px;
    background-color: rgba(0, 0, 0, 1);
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    z-index: 100;
    border-radius: 0 0 8px 8px;

    .left {
      color: #fff;
    }

    .right {
      color: #fff;
      display: flex;

      .tackCall {
        // width: 200px;
        height: 35px;
        line-height: 32px;
        display: flex;

        span {
          font-size: 15px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #E39F28;
          line-height: 32px;
        }

        .callOut {
          width: 110px;
          height: 32px;
          cursor: pointer;
          margin-left: 15px;
          border: 1px solid #fff;
          border-radius: 5px;
          display: flex;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;

          .iconOut {
            margin-left: 5px;
            width: 32px;
            height: 32px;
            background-size: 75% 75%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('~@/assets/img/ychj/callout.png');
          }
        }

        .callIn {
          width: 110px;
          height: 32px;
          cursor: pointer;
          margin-left: 15px;
          border: 1px solid #1ACD90;
          border-radius: 5px;
          display: flex;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          background-color: #1ACD90;

          .iconIn {
            margin-left: 5px;
            width: 32px;
            height: 32px;
            background-size: 75% 75%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('~@/assets/img/ychj/callin.png');
          }
        }

        .calling {
          width: 110px;
          height: 32px;
          cursor: pointer;
          margin-left: 15px;
          border: 1px solid #F55265;
          border-radius: 5px;
          display: flex;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          background-color: #F55265;

          .iconcalling {
            margin-left: 5px;
            width: 32px;
            height: 32px;
            background-size: 75% 75%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('~@/assets/img/ychj/calling.png');
          }
        }
      }

      .openDoor {
        cursor: pointer;
        height: 32px;
        width: 32px;
        margin-left: 15px;
        border: 1px solid #fff;
        border-radius: 5px;
        background-image: url('~@/assets/img/ychj/opendoor.png');
        background-size: 70% 70%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .closeDoor {
        cursor: pointer;
        height: 32px;
        width: 32px;
        margin-left: 15px;
        border: 1px solid #fff;
        border-radius: 5px;
        background-image: url('~@/assets/img/ychj/closedoor.png');
        background-size: 70% 70%;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

  }
}

.replacediv {
  // position: absolute;
  // display: flex;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // top: 0;
  // align-items: center;
  // justify-content: center;
  // background-color: rgba(0, 0, 0, 0.5);

  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  background-image: url('~@/assets/img/noMessage/videoDisconnect.png');
  background-size: 20% 45%;
  background-position: center center;
  background-repeat: no-repeat;
}

.recoder {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  color: #d81e06;
  font-size: 25px;
}

.videoBox {
  border: 1px solid black;
}

.callInMask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  background-image: url('~@/assets/img/ychj/callinginmove.gif');
  background-size: 65% 65%;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0 0 8px 8px;
  animation: maskColor 0.6s infinite;
}
/deep/ .el-button .el-button--text{
  width: 100% !important;
}
@keyframes maskColor {

  /*from表示动画的开始位置 也可以使用0%*/
  from {
    background-color: rgba(0, 0, 0, .8)
  }

  /*to动画的结束位置 也可以使用100%*/
  to {
    background-color: rgba(0, 0, 0, .6)
  }
}</style>
