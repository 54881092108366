<template>
  <!-- 工作台 -->
  <div class="contains-video" id="monitors">
    <div class="left">
      <div class="left_top-video">
        <template v-if="selectDeviceListSort.length > 0">
          <div v-for="i in howNumber" class="rownumber" :key="i">
            <div v-for="j in howNumber" :key="j" class="columnnumber">
              <parkInOutVideo v-show="howNumber !== 0 &&
                selectDeviceListSort[
                (i - 1) * howNumber + j - 1 + totolItem
                ] &&
                !isShow
                " :id="'videoElement' + ((i - 1) * howNumber + j - 1) + howNumber" :mainid="selectDeviceListSort[(i - 1) * howNumber + j - 1 + totolItem]
    ? selectDeviceListSort[
      (i - 1) * howNumber + j - 1 + totolItem
    ].mainId
    : ''
    " :height="videoheight" :fullscreenstatus="fullscreenstatus" :titleName="selectDeviceList[(i - 1) * howNumber + j - 1 + totolItem]
    ? selectDeviceList[
      (i - 1) * howNumber + j - 1 + totolItem
    ].parkname
    : ''" :videoData="selectDeviceList[(i - 1) * howNumber + j - 1 + totolItem]
    ? selectDeviceList[
    (i - 1) * howNumber + j - 1 + totolItem
    ] : ''" :canTakePhone="canTakePhone" :uniqueIdentifier='uniqueIdentifier' :tencentAppId="tencentAppId" :ref="selectDeviceList[(i - 1) * howNumber + j - 1 + totolItem]
    ? selectDeviceList[
      (i - 1) * howNumber + j - 1 + totolItem
    ].id : ''"></parkInOutVideo>
              <div class="noresource" v-if="howNumber === 0 ||
                !selectDeviceListSort[
                (i - 1) * howNumber + j - 1 + totolItem
                ] ||
                isShow
                ">
                <!-- <span> 无监控画面 </span> -->
              </div>
            </div>
          </div>
        </template>
        <div class="left_empty" v-else>暂无数据</div>
      </div>
      <div class="left_bottom">
        <div class="left_bottom_left">
          <i class="iconfont lebo-a-1" @click="showScreen(1)" :style="howNumber == 1 ? 'color:#32c5d2;' : ''"></i>
          <i class="iconfont lebo-a-2" @click="showScreen(2)" :style="howNumber == 2 ? 'color:#32c5d2;' : ''"></i>
          <i class="iconfont lebo-a-3" @click="showScreen(3)" :style="howNumber == 3 ? 'color:#32c5d2;' : ''"></i>
        </div>
        <div class="left_bottom_right">
          <i class="backpage" @click="upPage">
          </i>
          <span>
            {{ `第${page + 1}/${totalpage}屏` }}
          </span>
          <i class="nextpage" @click="nextPage">
          </i>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right_top">
        <!-- 树形选择 -->
        <div class="selectTree" @click="divClick">
          <selectTree ref="treeSelect" :data="officeTreeList" placeholder="请选择通道口" :defaultProps="defaultPropsTree"
            @parent-event="popoverHide" multiple :clear="clearTreeSelect" :nodeKey="nodeKey"
            :checkedKeys="defaultCheckedKeys" @popoverHide="popoverHide" :width="300" @tijiaoButton="queding">
          </selectTree>
        </div>
      </div>
      <div class="right_bottom">
        <div v-for="(item, index) in logs" :key="index" class="right_item" @click="onClickDevice(item)">
          <div class="parkDetail">
            <div class="leftImg">
              <template>
                <template v-if="item.go_in_image || item.go_out_image">
                  <el-image class="elImage"
                :src="item.out_in_type === 1 ? item.go_in_image : item.go_out_image" fit="cover">
                <div slot="error" class="image-slot">
                  <i class="iconfont lebo-picerr"></i>
                  <span>加载失败</span>
                </div>
              </el-image>
                </template>
                <template v-else class="noPic">
                     <span>{{item.out_in_type === 1 ?'暂无入场图片':'暂无出场图片'}}</span>
                        </template>
              </template>

              <!-- <img :src=item.go_in_image alt="" v-if="item.out_in_type === 1">
              <img :src=item.go_out_image alt="" v-else> -->
            </div>
            <div class="rightDetail">
              <div class="detailflex">
                <div class=" parckicon1"></div> <span>{{ item.plate_number }} </span>
                <div v-if="item.out_in_type === 2"
                  :class="item.order_Info.final_cost === 0 && item.order_Info.calculate_cost ? 'needPay' : 'payMoney'">
                  {{ payMoney(item.order_Info) }}</div>
              </div>
              <div class="detailflex">
                <div class="detailIcon parckicon2"></div><span>{{ getWayName('CarOwnerCardType',
                  item.car_owner_card_type) }}</span>
              </div>
              <div class="detailflex">
                <div class="detailIcon parckicon3"></div><span>{{ item.out_in_type === 1 ?
                  item.entryname : item.exportname }}</span>
              </div>
              <div class="detailflex">
                <div class="detailIcon parckicon4"></div>
                <span>{{ item.out_in_type === 1 ? $moment(item.go_in_time).format('YYYY-MM-DD HH:mm:ss') :
                  $moment(item.go_out_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="logs.length <= 0" class="noMessageMask"></div>
      </div>
    </div>
    <!-- 车辆进出场 -->
    <el-dialog :visible.sync="showCarsIn" :title="carsDetail.out_in_type === 1 ? '车辆入场' : '车辆出场'">
      <carsInPark :carsDetail="carsDetail"></carsInPark>
    </el-dialog>
    <!-- 来电铃声标签 -->
    <audio ref="videoPlayer" id="videoPlayer" :src="require('@/assets/music/commingCallmusice.mp3')" muted loop
      class="audioBox"></audio>
  </div>
</template>

<script>
import parkInOutVideo from './components/parkInOutVideo.vue'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getEntrancesList, getVideoAddress, getAccessStatus, obtainTencentConfiguration, getMqttConfiguration } from '@/api/ychjapi'
import carsInPark from './components/carsInPark.vue'
import selectTree from './components/selectTree.vue'
import mqtt from 'mqtt'
import * as LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js'
export default {
  components: {
    carsInPark, parkInOutVideo, selectTree
  },
  data () {
    return {
      howNumber: 2,
      page: 0,
      dialogVisible: false,
      deviceList: [],
      defaultPropsTree: {
        children: 'children',
        label: 'parkname'
      },
      landscaplist: [], // 整个树控件的数据
      treeSelectList: [], // 选中的场景节点
      selectDeviceList: [], // 右边下角的所有设备
      selectDeviceListSort: [], // 监控画面按照顺序排列的集合
      is_interval: false,
      interval_time: 1,
      timerInterval: null,
      isShow: false,
      videoheight: '100%',
      fullscreenstatus: false,
      showCarsIn: false, // 展示车辆出入
      carsDetail: {}, // 车辆进出入信息
      // 下拉树
      isShowSelect: false, // 选择面板，默认隐藏
      officeTreeList: [], // 树形结构数据
      selectedOfficeIds: '', // 选择后返回的选中id，以逗号拼接
      selectedOffices: [], // 选择后返回的选中对象，
      clearTreeSelect: 0,
      nodeKey: 'id', // 树形结构数据中对应的属性名称，改为自己数据中属性
      defaultCheckedKeys: [], // 默认初次选中的数据
      // mqtt
      client: '',
      message: '',
      logs: [], // 接收到的mqtt数据
      // 视频数据
      filterVideoList: [],
      resVideoList: [], // 获取到的视频信息
      infinityCallingList: [], // 轮询请求电话状态数组
      timeResInfinity: '', // 轮询定时器
      canTakePhone: true, // 是否可以接听电话
      uniqueIdentifier: '', // 证明是web端的id用于接听电话时传值
      tencentAppId: {}, // 腾讯云appid
      timeCheck: '', // 轮询定时器
      mqttMessage: {}, // mqtt配置信息
      infiniteList: [], // 轮询数组
      isNeedPickUp: false// 是否有人打电话进来

    }
  },
  computed: {
    ...mapState('menuList', ['groupInfo']),

    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList']),
    totalpage: function () {
      return this.selectDeviceListSort.length > 0
        ? Math.ceil(
          this.selectDeviceListSort.length / Math.pow(this.howNumber, 2)
        )
        : 1
    },
    totolItem: function () {
      return Math.pow(this.howNumber, 2) * this.page
    }
  },
  watch: {
    dialogVisible: function (newMsg, oldMsg) {
      if (newMsg) {
        if (this.is_interval && this.timerInterval !== null) {
          clearInterval(this.timerInterval)
          this.timerInterval = null
        }
        if (this.$refs.videoVideoTestDialog) {
          this.$refs.videoVideoTestDialog.initialize()
        }
      } else {
        if (this.is_interval && this.timerInterval === null) {
          this.timerInterval = setInterval(() => {
            this.nextPage()
          }, this.interval_time * 1000)
        }
        this.$refs.videoVideoTestDialog &&
          this.$refs.videoVideoTestDialog.flv_destroy()
      }
    },
    is_interval: function (newMsg, oldMsg) {
      if (newMsg) {
        if (!this.timerInterval) {
          this.timerInterval = setInterval(() => {
            this.nextPage()
          }, this.interval_time * 1000)
        }
      } else {
        if (this.timerInterval) {
          clearInterval(this.timerInterval)
          this.timerInterval = null
        }
      }
    },
    howNumber: {
      immediate: true, // 第一次改变就执行
      handler (newV, oldV) {
        if (newV === 1) {
          // this.videoheight = document.body.clientHeight - 155 + "px";
          this.videoheight = document.body.clientHeight - 190 + 'px'
        } else if (newV === 2) {
          this.videoheight =
            (document.body.clientHeight - 245) / newV - newV + 'px'
        } else if (newV === 3) {
          this.videoheight = (document.body.clientHeight - 304) / newV - newV + 'px'
        }
      }
    },
    isNeedPickUp: {
      // immediate: true,
      handler (newV) {
        if (newV) {
          this.$nextTick(() => {
            this.$refs.videoPlayer.muted = false
            // this.$refs.videoPlayer.load()
            this.$refs.videoPlayer.play()
          })
        } else {
          this.$refs.videoPlayer.pause()
          this.$refs.videoPlayer.load()
        }
      }
    }
  },
  created () {
    const that = this
    window.onresize = function () {
      if (!that.checkFull()) {
        // 退出全屏后要执行的动作
        // console.log("退出全屏")
        that.fullscreenstatus = false
      } else {
        // console.log("全屏")
      }
    }
  },
  mounted () {
    this.fnobtainTencentConfiguration() // 获取云配置信息
    document.querySelector('.contains-video').style.height =
      document.body.clientHeight - 120 + 'px'
    // this.videoheight = document.body.clientHeight - 585 + 'px' // 初始化监控显示
    this.initRightList()
    var that = this
    // 监听页面的显示与隐藏
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        // console.log("===-=-visible=-=-=");
        that.isShow = false
        if (that.is_interval && that.timerInterval === null) {
          that.timerInterval = setInterval(() => {
            that.nextPage()
          }, that.interval_time * 1000)
        }
      }
      if (document.visibilityState === 'hidden') {
        // console.log("===-=-hidden=-=-=");
        if (that.is_interval && that.timerInterval !== null) {
          clearInterval(that.timerInterval)
          that.timerInterval = null
        }
        that.isShow = true
      }
    })

    // 保存唯一uniqueIdentifier值
    this.uniqueIdentifier = sessionStorage.getItem('overexpirestime') + 'web'
    // this.selectedOfficeIds = ['e31e334e5648471abab05d07', 'c9e8d1ceb79b455c949323c8']// 第一次手动放进去，以后就直接用这个变量即可
    // mqtt初始化
    this.fngetMqttConfiguration()
    // this.mqttReceive()
  },
  methods: {
    checkFull () {
      // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull
    },
    fullScreen () {
      if (this.selectDeviceListSort.length <= 0) return
      this.fullscreenstatus = true
      var de = document.querySelector('.left_top-video')
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
    },
    showScreen (val) {
      if (this.selectDeviceListSort.length <= 0) return
      this.howNumber = 0
      setTimeout(() => {
        this.reset()
        this.howNumber = val
      }, 50)
    },
    reset () {
      this.page = 0
    },
    upPage () {
      if (this.selectDeviceListSort.length <= 0) return
      var that = this
      const pageSize = Math.ceil(
        this.selectDeviceListSort.length / Math.pow(this.howNumber, 2)
      )
      that.isShow = true
      if (this.page > 0) {
        setTimeout(() => {
          that.page -= 1
          that.isShow = false
        }, 50)
      } else {
        setTimeout(() => {
          that.page = pageSize - 1
          that.isShow = false
        }, 50)
      }
    },
    nextPage () {
      if (this.selectDeviceListSort.length <= 0) return
      var that = this
      const pageSize = Math.ceil(
        that.selectDeviceListSort.length / Math.pow(this.howNumber, 2)
      )
      that.isShow = true
      // console.log("this.page = " + this.page);
      if (that.page < pageSize - 1) {
        setTimeout(() => {
          that.page += 1
          that.isShow = false
        }, 50)
      } else {
        setTimeout(() => {
          that.page = 0
          that.isShow = false
        }, 50)
      }
    },
    onClickDevice (item) {
      this.carsDetail = item
      this.showCarsIn = true
    },
    totalNum (arry, oldArry) {
      if (arry && arry.length > 0) {
        for (var i in arry) {
          oldArry.push(arry[i]._id)
          if (arry[i].children.length > 0) {
            this.totalNum(arry[i].children, oldArry)
          }
        }
      }
    },
    traverse (arry) {
      if (arry && arry.length > 0) {
        for (var i in arry) {
          this.treeSelectList.push(arry[i].Id)
          if (arry[i].children.length > 0) {
            this.traverse(arry[i].children)
          }
        }
      }
    },
    getHomNumber (num) {
      if (num === 1) {
        return 1
      } else if (num > 1) {
        return 2
      } else {
        return 2
      }
    },
    traverseDevicesid (zi, arry) {
      if (arry && arry.length > 0) {
        for (var i in arry) {
          zi.push(arry[i].Id)
          if (arry[i].children.length > 0) {
            this.traverseDevicesid(zi, arry[i].children)
          }
        }
      }
    },
    // 获取右侧停车场数据
    async initRightList () {
      const res = await getEntrancesList()
      console.log('获取右侧停车场数据', res)
      this.officeTreeList = res.Data
      this.$nextTick(() => {
        if (this.$refs.treeSelect) {
          this.$refs.treeSelect.loadCheckedKeys()
        }
      })
      res.Data.forEach((fu) => {
        var children = [fu.id]
        if (fu.children.length > 0) {
          this.traverseDevicesid(children, fu.children)
        }
        this.deviceList.push(children) // 设备id
      })
      this.filterVideoList = []
      let filterTreeList = []
      filterTreeList = this.filterTreeData(res.Data, filterTreeList) // 所有出入口信息
      sessionStorage.getItem('selectChannel') // 获取之前存好的选项
      if (sessionStorage.getItem('selectChannel')) {
        filterTreeList = JSON.parse(sessionStorage.getItem('selectChannel'))
      }
      filterTreeList.forEach(item => {
        this.defaultCheckedKeys.push(item.id) // 默认全选
        this.filterVideoList.push({ // 获取视屏数据处理
          sn: item.camerasn || item.sn,
          type: 'flv',
          parkname: item.parkname,
          receive_name: item.receive_name,
          audiovideoType: item.audiovideoType,
          id: item.id,
          parprakingID: item.parprakingID,
          unique_identifier: item.unique_identifier
        })
      })
      console.log('filterTreeList', filterTreeList)
      this.fngetVideoAddress()
      this.infiniteList = []
      // ---渲染第一次画面
      this.selectDeviceListSort = filterTreeList
      this.infiniteList = this.getInfinityList(filterTreeList)
      // console.log('this.infiniteList ', this.infiniteList)
      sessionStorage.setItem('infiniteVideoList', JSON.stringify(this.infiniteList)) // 将选好的循环视频数组存储
      // this.getInfinityList(filterTreeList)
      this.selectDeviceList = filterTreeList
      clearTimeout(this.timeCheck)
      this.timeCheck = setTimeout(() => {
        this.fngetAccessStatus(this.infiniteList) // 开启轮询
      }, 1000)
      // 是其他页面跳转而来
      if (this.$route.query.id) {
        console.log('device', this.$refs[`${this.$route.query.id}`])
        this.howNumber = 1
        this.switchChange(this.$route.query.id)
      }
      this.mqttReceive() // 接受mqtt消息
    },
    // 获取出入口视频监控信息
    fngetVideoAddress () {
      this.resVideoList = [] // 获取到的视频信息
      this.filterVideoList.forEach(async item => {
        if (item.sn) {
          const res = await getVideoAddress({ sn: item.sn, type: item.type })
          this.resVideoList.push(res.Data)
        }
      })
      // console.log('视频数组', this.resVideoList)
      // this.selectDeviceListSort = this.resVideoList
      this.selectDeviceListSort = this.filterVideoList
      this.selectDeviceList = this.filterVideoList
      // console.log('渲染视频列', this.selectDeviceList)
    },
    // 下拉树方法
    popoverHide (checkedIds, checkedData) {
      // console.log('点击树', checkedIds)
      // console.log('节点数据', checkedData)
      this.selectedOfficeIds = checkedIds
      this.selectedOffices = checkedData
    },
    // 点击框关闭
    divClick () {
      this.$nextTick(() => {
        this.$refs.treeSelect.changeIsShowSelect()
      })
    },
    // 点击确定树选择
    queding (val) {
      this.isShowSelect = val
      this.$nextTick(() => {
        this.$refs.treeSelect.changeIsShowSelect()
      })
      // 关闭下拉框
      if (this.selectedOffices.length > 0) {
        clearInterval(this.timeResInfinity)
        this.infinityCallingList = []
        // console.log('this.selectedOffices', this.selectedOffices)
        // 筛选type为3且camerasn不为空的项
        this.filterVideoList = []
        this.selectedOffices.forEach(item => {
          if (item.type === 3 && item.camerasn !== '') {
            this.filterVideoList.push({
              sn: item.camerasn,
              type: 'flv',
              parkname: item.parkname,
              receive_name: item.receive_name,
              audiovideoType: item.audiovideoType,
              id: item.id,
              parprakingID: item.parprakingID,
              unique_identifier: item.unique_identifier
            })
          }
        })
        const rowList = []
        this.selectedOffices.forEach(item => {
          if (item.type === 3 && item.camerasn !== '') {
            rowList.push(item)
          }
        })
        this.infiniteList = []
        this.infiniteList = this.getInfinityList(rowList)
        // console.log('筛选视频数组', this.filterVideoList)
        // console.log('选择项', this.infiniteList)
        if (this.infiniteList.length > 0 && this.filterVideoList.length > 0) {
          this.fngetVideoAddress()
          sessionStorage.setItem('selectChannel', JSON.stringify(this.filterVideoList)) // 存储已选择通道口
          sessionStorage.setItem('infiniteVideoList', JSON.stringify(this.infiniteList)) // 将选好的循环视频数组覆盖
          this.reset()
          clearTimeout(this.timeCheck)
          this.timeCheck = setTimeout(() => {
            this.fngetAccessStatus(this.infiniteList) // 开启轮询
          }, 1000)
          this.mqttReceive()
        }
      } else {
        // 什么也没选就清空数据
        clearInterval(this.timeResInfinity)
        this.infiniteList = []
        this.filterVideoList = []
        sessionStorage.removeItem('selectChannel') // 清除存储已选择通道口
        sessionStorage.removeItem('infiniteVideoList') // 清除选好的循环视频数组
        this.selectDeviceListSort = []
      }
    },
    // 获取轮询数组
    getInfinityList (rowList) {
      // this.infinityCallingList = []
      let infinityLingList = []
      const dataInfo = {}
      rowList.forEach((item) => {
        const { parprakingID } = item
        if (!dataInfo[parprakingID]) {
          dataInfo[parprakingID] = {
            parprakingID,
            id_list: []
          }
        }
        dataInfo[parprakingID].id_list.push(item.id)
      })
      infinityLingList = Object.values(dataInfo)
      return infinityLingList
      // this.infinityCallingList = Object.values(dataInfo)
      // console.log('轮训数组', this.infinityCallingList)
    },
    // 轮询获取出入口状态
    fngetAccessStatus (checkList) {
      clearInterval(this.timeResInfinity)
      this.timeResInfinity = setInterval(async () => {
        const checkVideoStatusList = []
        const res = await getAccessStatus(checkList)
        // console.log('轮询状态', res)
        res.Data.forEach(item => {
          checkVideoStatusList.push({
            audiovideoType: item.audiovideoType,
            parkname: item.parkname,
            receive_name: item.receive_name,
            sn: item.camerasn,
            type: 'flv',
            id: item.id,
            parprakingID: item.parprakingID,
            unique_identifier: item.unique_identifier
          })
        })
        // console.log('轮询状态筛选', checkVideoStatusList)
        const changeConsquence = checkVideoStatusList.some((item, index) => {
          return item.audiovideoType !== this.selectDeviceList[index].audiovideoType
        })
        const isImTallking = checkVideoStatusList.some(item => {
          return item.audiovideoType === 2 && item.unique_identifier === this.uniqueIdentifier // 有人通话且通话的人是我自己
        })
        const needPickUp = checkVideoStatusList.some(item => {
          return item.audiovideoType === 1
        })
        this.canTakePhone = !isImTallking
        if (this.canTakePhone) { // 如果我可以接听则有人打进来就为true
          this.isNeedPickUp = needPickUp
        } else {
          this.isNeedPickUp = false
        }
        // console.log('changeConsquence', changeConsquence)
        if (changeConsquence) { // 查询到出入口接听状态改变重新渲染列表
          this.selectDeviceList = checkVideoStatusList
          // console.log('变化了selectDeviceList', this.selectDeviceList)
        }
      }, 500)
    },
    // 获取mqtt配置信息
    async fngetMqttConfiguration () {
      const res = await getMqttConfiguration()
      // console.log('mqtt配置', res)
      this.mqttMessage = {
        mqtt_port: res.Data.mqtt_port, // mqtt端口
        mqtt_pwd: res.Data.mqtt_pwd, // mqtt密码
        mqtt_url: res.Data.mqtt_url, // mqtturl
        mqtt_userid: res.Data.mqtt_userid // mqtt用户名

      }
      this.initMqtt()
    },
    // 初始化mqtt
    initMqtt () {
      const vm = this
      const originUrl = this.groupInfo.defaultDomain.split('//')[1]
      const commonApi = 'wss://' + originUrl + '/wss'
      // const commonApi = 'wss://fatcity.lebopark.com/wss' // 测试环境mqtt接口
      // const commonApi = 'wss://tcity.lebopark.com/wss' // 开发环境mqtt接口
      var mqtt = require('mqtt')
      var options = {
        // mqtt客户端的id
        clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
        clean: true, // 保留会话
        // 认证信息
        username: this.mqttMessage.mqtt_userid, // 用户名 不用的话什么也不用填
        password: this.mqttMessage.mqtt_pwd, // 密码 不用的话什么也不用填
        // username: 'mqtt', // 用户名 不用的话什么也不用填
        // password: 'hnlebokeji!@', // 密码 不用的话什么也不用填
        reconnectPeriod: 4000 // 重连时间间隔
      }
      vm.client = mqtt.connect(commonApi, options)
      this.client.on('connect', function () {
        console.log('连接成功....')
      })
      // 如果连接错误，打印错误
      vm.client.on('error', function (err) {
        console.log('err=>', err)
        vm.client.end()
      })
    },
    // 查找数组对象是否存在值
    findIndexByKeyValue (arr, key, valuetosearch, outInType) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][key] === valuetosearch && arr[i].out_in_type === outInType) {
          return i
        }
      }
      return -1
    },
    // 接受mqtt消息
    mqttReceive () {
      // const topic = 'topic/laneMonitoring/通道Id' // 要接收的主题
      // const topic = '/tcpservice/bydeviceno/2222' // 要接收的主题
      /// lebo/parkingrecord/ + 停车场ID
      // console.log('this.infiniteList222', this.infiniteList)
      const filterList = []
      this.infiniteList.forEach(item => {
        item.id_list.forEach(element => {
          filterList.push(element)
        })
      })
      this.infiniteList.forEach(item => {
        const topic = '/lebo/parkingrecord/' + item.parprakingID// 要接收的主题
        const vm = this
        vm.client.subscribe(topic, function (err) {
          if (!err) {
            console.log('subscribe success!')
          } else {
            // 打印错误
            console.log('err', err)
          }
        })
      })
      const vm = this
      vm.client.on('message', function (topic, message) {
        const getmessage = JSON.parse(message.toString())
        console.log('getmessage', getmessage)
        if (getmessage.out_in_type === 1) { // 入场数据
          if (filterList.indexOf(getmessage.entryid) !== -1) {
            if (vm.logs.length > 0) {
              const indexNum = vm.findIndexByKeyValue(vm.logs, 'entryid', getmessage.entryid, getmessage.out_in_type)
              // const indexNum = vm.logs.indexOf(getmessage.entryid)
              if (indexNum !== -1) {
                // console.log(' vm.logs[indexNum]', vm.logs[indexNum])
                vm.$set(vm.logs, indexNum, getmessage)
                // vm.logs[indexNum] = getmessage
              } else {
                vm.logs.unshift(getmessage)
              }
            } else {
              vm.logs.unshift(getmessage)
            }
          }
        } else { // 出场数据
          if (filterList.indexOf(getmessage.exportid) !== -1) {
            if (vm.logs.length > 0) {
              const indexNum = vm.findIndexByKeyValue(vm.logs, 'exportid', getmessage.exportid, getmessage.out_in_type)
              if (indexNum !== -1) {
                vm.$set(vm.logs, indexNum, getmessage)
                // vm.logs[indexNum] = getmessage[indexNum]
              } else {
                vm.logs.unshift(getmessage)
              }
            } else {
              vm.logs.unshift(getmessage)
            }
          }
        }
        // vm.logs.unshift(getmessage)
        console.log('接收到的mqttlog', vm.logs)
      })
    },
    // 获取腾讯云配置信息
    async fnobtainTencentConfiguration () {
      const res = await obtainTencentConfiguration()
      console.log('云配置', res)
      const getId = this.genTestUserSig(res.Data, this.uniqueIdentifier)
      this.tencentAppId = getId
    },
    // 获取userSig
    genTestUserSig (data, userID) {
      const SDKAPPID = parseInt(data.ZSYSPSDKAppID)
      const EXPIRETIME = 604800
      const SECRETKEY = data.ZSYSPSDKSecretKey
      if (SDKAPPID === '' || SECRETKEY === '') {
        return
      }
      const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME)
      const userSig = generator.genTestUserSig(userID)
      return {
        sdkAppId: SDKAPPID,
        userSig: userSig
      }
    },
    // 遍历获取最下面一项
    filterTreeData (data, arr) {
      data.forEach(item => {
        if (item.type === 2) {
          this.filterTreeData(item.children, arr)
        } else if (item.type === 3) {
          arr.push(item)
        }
      })
      return arr
    },
    // 遍历树形数组拿到所有项
    filterTreeDataAll (data, arr) {
      data.forEach(item => {
        if (item.type === 2) {
          arr.push(item)
          this.filterTreeData(item.children, arr)
        } else if (item.type === 3) {
          arr.push(item)
        }
      })
      return arr
    },
    // 快速定位视屏窗口
    switchChange (id) {
      const indexId = this.filterVideoList.findIndex(item => {
        return item.id === id
      })
      console.log('indexId', indexId, id)
      this.page = 0
      this.page = indexId
      this.$nextTick(function () { // 调用$nextTick函数
        console.log('id', this.$refs[`${id}`][0])
        this.$refs[`${id}`][0].tackCallIn()
      })
    },
    // 处理出场支付数据
    payMoney (data) {
      if (data.final_cost === 0 && data.calculate_cost !== 0) {
        return '待支付'
      } else {
        return '￥' + data.final_cost.toFixed(2)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timeResInfinity)
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timeResInfinity)
    next()
  }
}
</script>
<style scoped lang='less'>
.contains-video {
  display: flex;
  flex-direction: row;
  height: 100% !important;
  width: 100%;
  padding: 10px 0 20px 10px;
  box-sizing: border-box;
  overflow: hidden;

  .audioBox {
    display: none;
    visibility: hidden;
  }
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1;

  .left_empty {
    color: #909399;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.left_top-video {
  display: flex;
  flex-direction: column;
  flex: 1;

  .rownumber {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 1px;

    .columnnumber {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

.left_bottom {
  display: flex;
  flex-direction: row;
  height: 45px;
  padding: 0 1px;
  justify-content: space-between;
  align-items: center;
}

.left_bottom_left {
  margin-left: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .iconfont {
    font-size: 40px;
    cursor: pointer;
    margin-right: 5px;
  }
}

.left_bottom_right {
  margin-right: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &>span {
    font-size: 18px;
    margin: 0 10px;
  }

  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

.left_bottom_left>svg {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.left_bottom_right>svg {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

.right {
  display: flex;
  height: 100%;
  width: 17%;
  flex-direction: column;
  margin-right: 10px;
}

.vidoclass {
  display: flex !important;
  flex: 1 !important;
  width: 33%;
}

.right_top {
  display: flex;
  flex-direction: column;
  height: 50px;
  margin-bottom: 2px;
  background-color: white;
  overflow: auto;
  overflow: hidden;
}

.right_bottom {
  display: flex;
  height: 95%;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;

  // border: 1px solid black;
  .noMessageMask {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    background-image: url('~@/assets/img/ychj/nomessage.png');
    background-size: 92% 38%;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
  }

}

.right_item {
  display: flex;
  width: 100%;
  height: 150px;
  flex-direction: row;
  margin-top: 1px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 6px;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid #E6E6E6;
  box-shadow: 0 2px 4px 2px #E7E7E7;

  .parkDetail {
    width: 100%;
    height: 100%;

    .leftImg {
      width: 130px;
      height: 130px;
      overflow: hidden;
      // object-fit:cover;

      img {
        width: 100%;
        height: 100%;
      }

      .elImage {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid black;
        box-sizing: border-box;
        /deep/ .image-slot {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: #f5f7fa;

          // background-color: black;
          // font-size: 0;
          // background-image: url('~@/assets/img/noMessage/noCarPic.png');
          // // background-size: 60% 50%;
          // background-size:contain;

          // background-repeat: no-repeat;
          // background-position: center center;

          .iconfont {
            font-size: 16px;
          }
        }

      }
      .noPic{
                width: 100%;
                height: 100% !important;
                background-color: #f5f7fa;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999;
            }
    }

    .rightDetail {
      // background-color: yellow;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: left;

      .detailflex {
        display: flex;

        .needPay {
          margin-left: 25px;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #E39F28;
        }

        .payMoney {
          margin-left: 25px;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #1ACD90;
        }

        .parckicon1 {
          background-image: url('~@/assets/img/ychj/carbrand.png');
          background-size: 100% 100%;
          background-position: left bottom;
          width: 16px;
          height: 16px;
          margin: 0 3px;
        }

        .parckicon2 {
          background-image: url('~@/assets/img/ychj/carsize.png');
          background-size: 100% 100%;
          background-position: left bottom;
          width: 16px;
          height: 16px;
          margin: 0 3px;
        }

        .parckicon3 {
          background-image: url('~@/assets/img/ychj/commingplace.png');
          background-size: 100% 100%;
          background-position: right bottom;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          margin: 0 1px 0 5px;
        }

        .parckicon4 {
          background-image: url('~@/assets/img/ychj/timeicon.png');
          background-size: 100% 100%;
          background-position: left bottom;
          width: 16px;
          height: 16px;
          margin: 0 3px;
        }
      }

    }
  }

}

.right_item>div {
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
}

.videoModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  position: inherit;
  left: 50%;
  top: 65%;
  transform: translate(-50%);
  text-align: center;
  width: auto;
  height: 40px;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 0 20px;
  border-radius: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.noresource {
  // display: flex;
  // flex: 1;
  // background-color: rgba(0, 0, 0, 0.5);
  // align-items: center;
  // justify-content: center;
  display: flex;
  flex: 1;
  // background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  background-image: url('~@/assets/img/noMessage/videoNoSetting.png');
  background-size: 20% 45%;
  background-position: center center;
  background-repeat: no-repeat;
}

.el-dialog__wrapper {
  /deep/.el-dialog {
    // height: 55%;
    border-radius: 8px;

    .el-dialog__header {
      height: 50px !important;
      background-color: RGBA(242, 251, 255, 1);
      text-align: left;
      box-sizing: border-box;
      padding: 15px 0 5px 20px !important;
      border-radius: 8px;

      .el-dialog__title {
        color: #01A6FB;
        font-size: 18px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        line-height: 21px;
        width: 200px;
        height: 100%;
        display: flex;

        &::before {
          content: '';
          // background-color: #fff;
          width: 18px;
          height: 19px;
          margin-right: 4px;
          display: block;
          background-image: url('~@/assets/img/ychj/dialogicon.png');
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      .el-dialog__headerbtn {
        font-size: 22px;
        padding-bottom: 20px;

        .el-dialog__close {
          color: #01A6FB !important;
        }
      }
    }

    .el-dialog__body {
      height: 100%;
      padding: 0 !important;
    }
  }
}

.backpage {
  width: 30px;
  height: 30px;
  background-image: url('~@/assets/img/ychj/backarrowicon.png');
  background-size: 100%;
  font-size: 26px;
  cursor: pointer;
}

.nextpage {
  width: 30px;
  height: 30px;
  background-image: url('~@/assets/img/ychj/nextarrowicon.png');
  background-size: 100%;
  font-size: 26px;
  cursor: pointer;
}

.main-select-el-tree .el-tree-node .is-current>.el-tree-node__content {
  font-weight: bold;
  color: #409eff;
}

.main-select-el-tree .el-tree-node.is-current>.el-tree-node__content {
  font-weight: bold;
  color: #409eff;
}

.selectTree {
  display: flex;
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
}

// 滚动条配置
/deep/::-webkit-scrollbar {
  width: 2px;
}

/deep/::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(0, 0, 0, 0.2);
}

/deep/::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  // background: rgba(0,0,0,0.1);
}

// /deep/ ::-webkit-scrollbar { /* 样式穿透 */
//     display: none;
// }</style>
